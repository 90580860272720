import React from 'react';
import {infoContact}  from './contact.holder';
import './ContactInfo.style.css'

const ContactInfo=()=>{

    return (
      <div>{
        infoContact.map((infoContact)=>{
          return(

<div className='infoContact-container' key={infoContact.contactName}>
 <p  data-aos="fade-up"  className='infoContact-name'>   {infoContact.contactName}</p>
    <p  data-aos="fade-up"className='infoContact-info'>{infoContact.contactInfo}</p>

  </div>

           )}) }</div>

    );
}
 export default ContactInfo;