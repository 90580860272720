import React, {useEffect} from 'react';
import { Slide } from 'react-slideshow-image';
import './SlideShow.style.css'
import Aos from 'aos'
import 'react-slideshow-image/dist/styles.css';

 
const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: false,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  }
}
const SlideShow = () => {
  useEffect(()=>{
    Aos.init({duration:2000});
  },[]);
    return (
      <div  className='slide-component-container'>
 
        <Slide data-aos="fade-in"    {...properties} className="slide-container" >
          <div className="each-slide">
              <img className='slides' src={require('../../images/slide1.jpg')} alt="11" />
            </div>
          <div className="each-slide">
             <img  className='slides' src={require('../../images/slide2.jpg')} alt="2" />
            </div>
    
        </Slide>
      </div>
    )
}


export default SlideShow;