import React, {useEffect} from 'react';
import CaruselFirst from '../component/carusel-component/CaruselFirst.component';
import CaruselSecond from '../component/carusel-component/CaruselSecond.component';
import './ProductPage.style.css'
import Aos from 'aos';


const ProductPage=()=> {
  useEffect(()=>{
    Aos.init({duration:2400});
  },[]);
  return ( 

  <div data-aos="fade-up" className='product-page'>

  <h1 >Haши производи</h1> 
  <div  className=''>
    < CaruselFirst />
     <CaruselSecond/>
  
 </div>
</div>

  );
 }

export default ProductPage;