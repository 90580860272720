export const infoContact=[
    {
        contactName:'Телефон',
        contactInfo:'011/305-40-23   011/305-40-24  ',
        
        contactIcon:'https://firebasestorage.googleapis.com/v0/b/marijana-portfolio-website.appspot.com/o/portfolio-images%2Fviber.png?alt=media&token=626e63fa-2620-4342-abe4-bbd6123c8b5c'
       
},

{
    contactName:'Емаил',
    contactInfo:'zavondoo@gmail.com',
    contactIcon:''
   
},
{
    contactName:'Адреса',
    contactInfo:'Република Србија,11030 Београд, Чукарица,Требевићка 16',
    contactIcon:''
   
},
{
    contactName:'Радно време',
    contactInfo:'Понедељак-Петак 07-15 ч',
    contactIcon:''
   
}]

export const infoCompany=[
    {
    companyName:'Делатност',
        companyInfo:'Производња фармацеутских препарата'
    
       
},
{
    companyName:'Матични број',
    companyInfo:'17302817'

   
},
{
    companyName:'ПИБ',
    companyInfo:'102142858'

   
},
{
    companyName:'Текући рачун',
    companyInfo:'205-509925-03'

   
}]