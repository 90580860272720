import React,{useEffect}  from 'react';
import CompanyInfo from '../component/contact-component/CompanyInfo.component';
import ContactInfo from '../component/contact-component/ContactInfo.component';
import './ContactPage.style.css';
import Aos from 'aos';


const Contact =()=>{
 useEffect(()=>{
   Aos.init({duration:2400});
  },[]);
return(
  <div className='contact-container' >
    <div>
   <h2 data-aos="fade-up">Подаци о предузећу</h2> 
<CompanyInfo/> 
    </div>
    <div>

<h2 data-aos="fade-up"  >Контакт информације</h2>  
<ContactInfo/>
    </div>
     <div data-aos="fade-up"  className='map-image'>

     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2832.7041976966475!2d20.41513865152967!3d44.76644707899636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a6e282dec3593%3A0x8b7958217e3313bf!2sZavon%20d.o.o.!5e0!3m2!1ssr!2srs!4v1662939210135!5m2!1ssr!2srs"   style={{width:'76vw',height:'320px'}} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 
 </div>

  </div>
);
 
}


export default Contact;
