import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import './Carusel.style.css';

class CarouselSecond extends Component {
  render(){
  return ( 
  <div className='carusel'>
         <h3 className=''>ИНСЕКТИЦИДИ</h3>
   <Carousel >
    <div>
       <img src={require('../../images/insekticidi.jpg')} alt="im3"/>
  
      </div>
      <div>
       <img src={require('../../images/frakcinol.jpg')} alt="caf8"/>

         <p  className='legend'>

         Frakciol plus losion препарат на бази диетилтолуоламида 18% делује на комарце репелентно, штитећи третиране делове коже. Репелент делује на артроподе блокирајући хеморецепторе олфакторних органа, да осете мирис коже, зноја и CO2 које емитује домаћин. На изложене делове коже, које желимо да заштитимо, нанети лосион. Препарат распршити 2-3 путa на откривене делове коже сa удаљености од 10-15cm, а затим благо утрљати. Једно распршивaњe покрива  површину oд 200cm2, штo oдгoвaрa пoвршини пoдлaктицe oдрaслoг чoвeкa.Паковање: 100ml
         </p>
       
      </div>
      <div>
       <img src={require('../../images/biostop.jpg')} alt="caf11"/>
   
         <p className='legend'>
           Биостоп 10 ЕЦ, инсектицид на бази перметрина 5% и пиперонил бутоксида5%. Раствор се употребљава за сузбијање бубашваба у објектима јавног здравља, и у другим јавним објектима као и за сузбијање крпеља и комараца. Може се употребити у затвореном и отвореном простору. 
Паковање: 100ml и 500ml
           </p>
      
      </div>
      <div>
       <img src={require('../../images/ekokreda.jpg')} alt="caf9"/>
       
    
       <p className='legend'>

       Еко креда средство на бази перметрина 2% , ефикасно делује против мрава у домаћинствима, угоститељству, објектима јавног здравља, прехрамбеној индустрији, пословном простору и другим објектима. Кредом исцртати места куда се крећу мрави, нарочито на спојевима између зидова и подова, око намештаја, техничких уређаја као и око водоводних, топловодних и канализационих инсталација. Једна креда (тежи 4g), довољна је да се покрије површина од 3,6m2.Биоцидни производ делује на жутe мравe – Monomorium pharaonis L. и црнe мравe Lasius niger. Паковање: 4 креде
       </p>
       
      </div>
      <div>
       <img src={require('../../images/bubiprasak.jpg')} alt="caf12"/>

         <p className='legend'>

         Буби прашак је ефикасно средство на бази перметрина 2%, против мрава и бубашваба у домаћинствима, угоститељству, објектима јавног здравља, прехрамбеној индустрији, пословном простору и другим објектима. Препарат се користи тако што се одврне горњи чеп на паковању, па боцу окренути на доле како би се извршила апликација преко сита које се налази на отвору амбалаже. Једна апликација износи 2g и покрива површину од 1m2 (довољно да остане видљив траг на површини која се третира). Биоцидни производ делује на жуте мраве-(Monomorium pharaonis L.), црне мраве-(Lasius niger), смеђе бубашвабе (Blattella germanica) и црне бубашвабе (Blatta оrientalis).Паковање: 100g
         </p>
   
      </div>
      <div>
       <img src={require('../../images/blattacid.jpg')} alt="caf10"/>
    
         <p className='legend'>

         Blattacid klopke инсектицид на бази бендиокарба 1%, намењен за сузбијање бубашваба и бубаруса у домаћинствима, угоститељству, објектима јавног здравља, прехрамбеној индустрији, пословном простору и другим објектима. Препарат се користи тако што се по један мамац-клопка, постави на сваких 10m2 површине, на местима где се бубашвабе и бубарусе крећу или задржавају (око судопера, бојлера, електро уређаја, кухињских елемената, између подова и зидова, око водоводних инсталација и сл.) 
       Биоцидни производ делује на смеђе бубашвабе (Blatella germanica) и црне бубашвабе (Blatta orientalis).Паковање: 3 клопке
         </p>
   
      </div>
     </Carousel>
    </div>

  );
 }
}

export default CarouselSecond;