import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Particles from 'react-particles-js';
import './Header.style.css';


const particlesOptions={
   particles: {
     number:{
      value:150,
    density:{
     enable:true,
     value_area:1000
     }
    }
  }
}

class Header extends Component {
 render(){
  return (
  <div className='header-component'>
  <Particles className='particles' params={particlesOptions}/>
 <div data-aos="fade-in"    data-aos-duration="1800" >
   <img className='logo' src={require('../../images/zavonlogo3d.png')} alt="logo1"/>
    <h4 >Завон д.о.о Предузеће за производњу фармацеутцких препарата </h4>

    
      <ul className="nav-bar" >
      <li className='nav-buttons' >
        <Link to="/" className='nav-link' >  О нама  </Link>
      </li>
      <li className='nav-buttons' >
        <Link to="/proizvodi" className='nav-link' > Производи </Link>
      </li>
      <li className='nav-buttons' >
        <Link to="/kontakt" className='nav-link' > Контакт  </Link>
      </li>
      </ul>
 
      </div>
   </div>
 
  );
 }
}

export default Header;