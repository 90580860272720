import React from 'react';
import  {infoCompany} from './contact.holder';
import  './CompanyInfo.style.css';

const CompanyInfo=()=>{
    return(
    <div style={{alignItems:'center',
    justifyContent:'center'}} >{
        infoCompany.map((infoCompany)=>{
    return (
<div className='infoCompany-container'    key={infoCompany.companyName}>

    <p data-aos="fade-up"  className='infoCompany-name'>{infoCompany.companyName}</p>
        <p  data-aos="fade-up"  className='infoCompany-info'>{infoCompany.companyInfo}</p>
 
  </div>
    );})}</div>)
}
 export default CompanyInfo;