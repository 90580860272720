import React,{Component} from 'react';
import {BrowserRouter as Router,Route, Switch} from 'react-router-dom';
import Header from './component/header-component/Header.component';

import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage.jsx';
 import ContactPage from './pages/ContactPage';

import './App.css';
import 'aos/dist/aos.css';
import FooterComponent from './component/footer-component/Footer.component.js';


class App extends Component {
  render(){
  return (
    <Router>
    <div className="App">
        <Header/> 
        <Switch>

       <Route exact path='/' component={HomePage}/>
       <Route exact path='/proizvodi' component={ProductPage}/>
       <Route exact path='/kontakt' component={ContactPage}/>  
        </Switch>
  <FooterComponent/>

    </div>
    </Router>
  );
 }
}

export default App;