import React, {useEffect} from 'react';
import SlideShow from '../component/slideshow-component/SlideShow.component';
import Aos from 'aos';

import  './HomePage.css';

const HomePage =()=> {
  useEffect(()=>{
    Aos.init({duration:2800});
  },[]);
  return (
  <div className="">
    <SlideShow/>
 <div className='homepage-container'>
 
  <h1 className=''>О нама</h1>
   
  <div className='homepage-text-container'>
  <p  data-aos="zoom-in">
Делатност предузећа  Завон  доо је  производња  фармацеутских  препарата  који  се  првенствено  користе  у  здравственим  установама  за  спровођење  превентивних  и  противепидемијских  мера,  дезинфекције  и  антисепсе.
  </p>
<p data-aos="fade-right" >
Програм дезинфекционих антисептичких средстава базиран је на дугогодишњем стручном искуству Завода у пружању услуга дезинфекције и антисепсе у здравственим установама.
</p>
<p data-aos="fade-up">
Поред наведеног, коришћена су најновија сазнања, препоруке и достигнућа у свету, при чему се као основне испоштоване препоруке: Светске здравствене организације (WHО), Центра за контролу и превенцију болести (CDC ) из Атланте и Директиве о биоцидима Европске Уније.
</p>
<p data-aos="fade-up" >
Дезинфекциона и  антисептичка  средства,  из  наведеног  програма  се  користе  у  многим  здравственим  установама. Од  стране  корисника  су  оцењена  као  веома  квалитетна  и  ефикасна. Наведени  препарати  се  примењују  у  фабрикама  за  производњу  хране,  које  поседују  сертификат  HCCP.
</p><br/>
<p data-aos="fade-up"  >
Сви производи  су  тестирани  и  испитани  у  установама  које  су  овлашћене  за  контролу  и  квалитет  поменутих  производа. Такође  сви производи имају решење министарства за заштиту животне средине и одговарајући безбедоносно технолошки лист.
</p>
 </div> 

</div>
  </div>
  );
 }


export default HomePage;