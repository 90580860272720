import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import './Carusel.style.css';

class CarouselFirst extends Component {
  render(){
  return ( 
  <div className='carusel'>

<h3 className=''>ДЕЗИНФИЦИЈЕНСИ</h3>    
  <Carousel  >
    <div >
       <img src={require('../../images/dezificijensi.jpg')} alt="cafdez"/>
    
      </div>
        <div>
       <img  src={require('../../images/desoldez.jpg')} alt="caf5"/>
  
              <p className='legend'>
                      Десол–Дез је дезинфицијенс на бази натријум-дихлороизоцијанурата,  широког антимикробног спектра са применом у прехрамбеној индустрији, хотелијерству, у објектима здравства, ветерине (ветеринарске амбуланте) и  објектима  за чување стоке. Користи се за дезинфекцију површина (подови, зидови), површина која долази у контакт са храном, опреме која није осетљива на хлор. 
Дезинфекција предмета, површина, опреме и уређаја се врши потапањем, брисањем и прскањем ручном прскалицом  раствора у концентрацији од најмање 1g у 5L воде. Дезинфекција објеката за смештај животиња врши се растварањем  2g у 5L воде. Дезинфекција површина  контаминираних материјалима као што су: гној, крв, фекалије врши се растварањем  10g у 1L  воде.
Паковање: 100g, 500g и 1kg
              
              </p>

      </div>
      <div >
       <img   src={require('../../images/desol.jpg')} alt="caf1"/>
 
              <p className='legend'>

              Десол дезинфекционо средство на бази бензалконијум хлорида 1% намењено за дезинфекцију површина прибора и опреме, дезинфекцију и дезодорацију санитарних уређаја, намештаја, као и дезинфекцију површина које долазе у контакт са храном. Употребљава се у домаћинствима, јавним и индустријским објектима, здравству, хотелијерству, угоститељству,ветеринарству (објекти за   држање и чување животиња), прехрамбеној индустрији. 
Дезинфекција се врши наношењем готовог радног раствора директно из оригиналног паковања или крпом (распршивањем, прањем, пребрисавањем, потапањем у раствор).
Паковање: 200ml, 400ml, 1l, 5l и 20l
              </p>

      </div>
      <div>
       <img    src={require('../../images/tinktura.jpg')} alt="caf3"/>

   
 <p  className='legend'>
Завон тинктура је готов радни раствор који садржи 0,5% хлорхексидин-диглуконата. Користи се за дезинфекцију и одржавање личне хигијене руку, као и за дезинфекцију предмета и радних површина које долазе у контакт са храном и пићем у домаћинствима, јавним и индустријским објектима, укључујући и објекте јавног здравља.
Дезинфекција руку врши се након детаљног прања сапуном и испирања водом, након чега се препарат наноси директно из оригиналне амбалаже распршивањем на дланове, а након тога благо утрља уз експозицију од најмање 1 минута.
Дезинфекција радних површина и површина  које долазе у контакт са храном и пићем, врши се наношењем готовог радног раствора распршивањем или пребрисавањем уз експозицију од  најмање 1 минута.
Дeзинфeкциja пребрисавањем врши се пoмoћу чистe ткaнинe кojа је довољно нaтoпљeна биоцидним производом. Пoвршинe кoje сe дeзинфикуjу претходно мoрajу бити oчишћeнe и сувe. Пoслe пребрисавања дeзинфикoвaну пoвршину oстaвити дa сe oсуши. 
Паковање: 200ml, 500ml, 1l и 5l  
  
 </p>

      
      </div>
       <div>
       <img src={require('../../images/krema.jpg')} alt="caf4"/>
    
         <p className='legend'> 
                 Завон крема садржи 1%  D-глуконске киселине, једињења са  N,N"- bis(4-hlorofenil)-3,12-dimino-2,4,11,13-tetraazatetradekandiamidin(2:1) у хидрофилној подлози, има дезинфекционо дејство, а истовремено негује здраву кожу руку. Користи се у свим ситуацијама када се захтева висок ниво хигијене руку, тим више што пар часова штити од накнадног продора штетних микроорганизама. 
                 Препоручује се као средство за хигијенски третман руку. Завон крема се наноси истискивањем садржаја  из тубе у количини од 0.5g (количина зрна грашка)  и благо утрљава на претходно опрану и осушену кожу руку.Паковање: 50g
         </p>
            
     
      </div>
      <div>
       <img src={require('../../images/dezoral.jpg')} alt="caf6"/>
    
        <p className='legend'>
 Дезорал је орални дезинфицијенс  намењен за испирање уста и грла. Препоручује се и  као освеживач даха.
Препоручује се једна супена кашика неразблаженог раствора, испирајући уста и грло, у временском интервалу од 30 секунди.
Паковање: 150ml и 1l 
        </p>
                
     
      </div>
      <div>
       <img src={require('../../images/citsolc.jpg')} alt="caf2"/>
     
           <p className='legend'>

                     Citosol C шампон садржи 0,5% хлорхексидин диглуконата уз додатак емолијената и користи се за дезинфекцију и одржавање личне хигијене људи, за хируршко и хигијенско прање руку, а погодан је и за медицинско прање пацијената у здравственим установама.
Хигијенско и хируршко прање се врши наношењем шампона  на кожу руку, а ако се употребљава као купка додаје се одређена количина у празну каду пре сипања воде.
За дезинфекцију и хигијенско и хируршко прање руку: 3-5 ml препарата, а као купка: 20-100 ml препарата (према потреби).
Паковање: 450ml, 1l и 5l
           </p>
                    
   
      </div>
        <div>
       <img src={require('../../images/cisolh.jpg')} alt="caf7"/>
      
           <p  className='legend'>

                     Citosol H садржи мешавину етанола и пропанола а користи се за одржавање личне хигијене људи, као и у домаћинствима, објектима јавног здравља, индустријским објектима. Препоручује се за хигијенски третман руку, односно на свим местима где се захтева висок ниво хигијене руку: у болницама, ординацијама, хируршким салама, затим ветеринарској медицини, прехрамбеној индустрији, угоститељству, хотелијерству и сл. 
Хигијенски третман се врши наношењем гела на кожу руку, врхова прстију и између прстију, уз утрљавање док алкохол испари.
Паковање: 65ml, 330ml и 1l 
                    
           </p>
    
      </div>
  </Carousel> 
  </div>

  );
 }
}

export default CarouselFirst;