import React,{useEffect} from 'react'
import './Footer.style.css';
import Aos from 'aos'


const FooterComponent =()=>{
   useEffect(()=>{
      Aos.init( {offset: 1});
    },[]);
    return(
    <div className='footer-component'   > 
   © 2020. 
   <a data-aos="fade-in"    href="https://marijana-portfolio-website.web.app" target="_blanck" rel="noopener noreferrer"> 
   <img data-aos="fade-in"   src={require('../../images/my-logo.png')} alt="foto" />
      </a>
     за Завон д.о.о., Сва права задржана

    </div>
 )
}
export default FooterComponent;